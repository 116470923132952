import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

// Create AuthContext
const AuthContext = createContext({
  idToken: null,
  setIdToken: () => { },
  logout: () => { },
  addAuthHeader: () => ({}),
  isLoading: true,
});

// Create a hook to use AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [idToken, setIdToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      const storedIdToken = localStorage.getItem('idToken');
      if (storedIdToken) {
        setIdToken(storedIdToken);
      }
    } catch (error) {
      console.error('Failed to load idToken from localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (idToken) {
      localStorage.setItem('idToken', idToken);
    }
  }, [idToken]);

  const logout = () => {
    setIdToken(null);
    localStorage.removeItem('idToken');
  };

  const addAuthHeader = useCallback((headers = {}) => {
    if (idToken) {
      return {
        ...headers,
        Authorization: `Bearer ${idToken}`,
      };
    }
    return headers;
  }, [idToken]);

  return (
    <AuthContext.Provider value={{ idToken, setIdToken, logout, addAuthHeader, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
